var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "content-title" }, [
        _vm._v(_vm._s(_vm.content.title))
      ]),
      _c("h4", { staticClass: "content-subtitle" }, [
        _c("i", [_vm._v(_vm._s(_vm.content.sub_title))])
      ]),
      _c("span", { staticClass: "question-type-container" }, [
        _vm._v(_vm._s(_vm.contentType))
      ]),
      _c("div", { staticClass: "content-author-container" }, [
        _c(
          "div",
          { staticClass: "content-author" },
          [
            _c(
              "router-link",
              { staticClass: "author-link", attrs: { to: _vm.authorLink } },
              [
                _c("avatar", {
                  attrs: { size: "medium", image: _vm.author.avatar }
                })
              ],
              1
            ),
            _c("div", { staticClass: "author-info" }, [
              _c(
                "a",
                { staticClass: "author-link", attrs: { href: _vm.authorLink } },
                [
                  _c("span", { staticClass: "author-name" }, [
                    _vm._v(_vm._s(_vm.author.display_name))
                  ])
                ]
              ),
              _c("span", { staticClass: "author-info-item" }, [
                _vm._v(_vm._s(_vm.occupation))
              ]),
              _c("span", { staticClass: "author-info-item" }, [
                _vm._v(_vm._s(_vm.createdAt))
              ])
            ])
          ],
          1
        ),
        !_vm.shareDisabled
          ? _c(
              "div",
              { staticClass: "share-button" },
              [_c("share-link-dialog", { attrs: { shareUrl: _vm.shareUrl } })],
              1
            )
          : _vm._e()
      ]),
      _vm.content.image_url || _vm.content.video_url
        ? _c("image-video", {
            attrs: {
              imageUrl: _vm.content.image_url,
              videoUrl: _vm.content.video_url
            }
          })
        : _vm._e(),
      _c("v-card-text", { staticClass: "content-detail" }, [
        _vm._v(" " + _vm._s(_vm.content.body) + " ")
      ]),
      _c("div", [
        _vm.content.action_title
          ? _c(
              "a",
              { attrs: { href: _vm.content.action_url, target: "_blank" } },
              [
                _c(
                  "inqli-button",
                  { attrs: { "aria-label": _vm.content.action_title } },
                  [_vm._v(_vm._s(_vm.content.action_title))]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }