<template>
  <div>
    <img
      class="image-preview rounded-lg"
      contain
      :src="imageUrl"
      v-if="imageUrl && !videoUrl"
    />
    <div v-else-if="videoUrl && isEmbedVideo" class="embed-wrapper">
      <iframe
        :src="videoUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="rounded-lg embed"
      ></iframe>
    </div>
    <video
      class="rounded-lg"
      v-else-if="videoUrl"
      width="90%"
      controls
      :poster="imageUrl"
    >
      <source :src="videoUrl" type="video/mp4" />
      Your browser does not support HTML video.
    </video>
  </div>
</template>

<script>
import { isEmbedVideoUrl } from "../../../app/utils/video-helper";

export default {
  name: "ImageVideo",
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
    videoUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    isEmbedVideo() {
      return isEmbedVideoUrl(this.videoUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.image-preview {
  margin: 10px 0;
  border-radius: 16px;
  max-width: 100%;
  max-height: 400px;
}
.embed-wrapper {
  position: relative;
  padding-bottom: 50.25%;
  height: 0;
  overflow: hidden;
}
.embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
@media (max-width: $breakpoint-mobile) {
  .embed-wrapper {
    margin-bottom: 20px;
  }
}
</style>
