<template>
  <div>
    <h1 class="content-title">{{ content.title }}</h1>
    <h4 class="content-subtitle">
      <i>{{ content.sub_title }}</i>
    </h4>
    <span class="question-type-container">{{ contentType }}</span>
    <div class="content-author-container">
      <div class="content-author">
        <router-link class="author-link" :to="authorLink"
          ><avatar size="medium" :image="author.avatar"
        /></router-link>
        <div class="author-info">
          <a :href="authorLink" class="author-link"
            ><span class="author-name">{{ author.display_name }}</span></a
          >
          <span class="author-info-item">{{ occupation }}</span>
          <span class="author-info-item">{{ createdAt }}</span>
        </div>
      </div>
      <div class="share-button" v-if="!shareDisabled">
        <share-link-dialog :shareUrl="shareUrl" />
      </div>
    </div>
    <image-video
      v-if="content.image_url || content.video_url"
      :imageUrl="content.image_url"
      :videoUrl="content.video_url"
    />
    <v-card-text class="content-detail">
      {{ content.body }}
    </v-card-text>
    <div>
      <a :href="content.action_url" target="_blank" v-if="content.action_title">
        <inqli-button :aria-label="content.action_title">{{
          content.action_title
        }}</inqli-button>
      </a>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import InqliAvatar from "../../app/components/avatars/Avatar.vue";
import ShareLinkDialog from "../../app/components/dialogs/ShareLinkDialog.vue";
import InqliButton from "../../app/components/buttons/OutlineButton.vue";
import ImageVideo from "../../app/components/media/ImageVideo.vue";

export default {
  name: "contentDetail",
  components: {
    avatar: InqliAvatar,
    "share-link-dialog": ShareLinkDialog,
    "inqli-button": InqliButton,
    "image-video": ImageVideo,
  },
  props: {
    content: Object,
    author: Object,
    shareDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    contentType() {
      return this.content.type.replace("iac-", "");
    },
    occupation() {
      let info = this.author.org_detail ? `${this.author.org_detail}` : "";
      info += this.author.org ? ` at ${this.author.org}` : "";
      return info;
    },
    createdAt() {
      return moment.utc(this.content.created_on).local().format("MMM DD, YYYY");
    },
    authorLink() {
      return `/users/${this.author.id}`;
    },
    shareUrl() {
      return `${window.location.origin}/contents/${this.content.id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-detail-container {
  border-radius: 16px;
  padding: 20px 55px;
  margin: 0 10px;
}
.content-title {
  text-align: center !important;
  margin-top: 5px;
  color: $inqliNavy;
  font-size: 20px;
}
.content-subtitle {
  color: $inqliGreen;
}
.content-author-container {
  display: flex;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
}
.content-author {
  display: flex;
  margin: 5px auto;
}
.author-info {
  margin-left: 10px;
}
.author-info-item {
  display: block;
  font-size: 11px;
  text-align: left;
  color: $inqliGray;
}
.content-detail {
  padding: 10px 0;
  font-size: 14px;
  color: $inqliNavy !important;
  text-align: left;
  white-space: break-spaces;
}
.author-link {
  font-size: 14px;
  text-decoration: none;
  color: $inqliNavy !important;
  font-weight: 600;
  .author-name {
    text-align: left !important;
    display: block;
    margin-bottom: -1px;
    margin-top: -3px;
  }
}
.author-link:hover {
  color: $inqliButtonHover;
}
.author-link:focus {
  color: $inqliButtonFocus;
}
.share-button {
  position: absolute;
  right: -15px;
  top: 15px;
}
.question-type-container {
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
  background-color: $inqliNavy !important;
  color: white;
  border: 2px solid #ffffff;
  font-size: 9px;
  padding: 2px 0;
  border-radius: 4px;
  display: block;
  width: 80px;
  margin: auto;
  margin-bottom: 10px;
}
@media (max-width: $breakpoint-mobile) {
  .content-author {
    display: block;
    text-align: center;
    .author-info {
      margin-top: 5px;
      span {
        text-align: center !important;
      }
    }
    .author-link {
      display: block;
      margin: auto;
      span {
        text-align: center;
      }
    }
  }
}
</style>
