var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.imageUrl && !_vm.videoUrl
      ? _c("img", {
          staticClass: "image-preview rounded-lg",
          attrs: { contain: "", src: _vm.imageUrl }
        })
      : _vm.videoUrl && _vm.isEmbedVideo
      ? _c("div", { staticClass: "embed-wrapper" }, [
          _c("iframe", {
            staticClass: "rounded-lg embed",
            attrs: {
              src: _vm.videoUrl,
              frameborder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: ""
            }
          })
        ])
      : _vm.videoUrl
      ? _c(
          "video",
          {
            staticClass: "rounded-lg",
            attrs: { width: "90%", controls: "", poster: _vm.imageUrl }
          },
          [
            _c("source", { attrs: { src: _vm.videoUrl, type: "video/mp4" } }),
            _vm._v(" Your browser does not support HTML video. ")
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }