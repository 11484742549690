const applicableVideoHost = {
  "youtube.com": "www.youtube.com/embed",
  "youtu.be": "www.youtube.com/embed",
  "vimeo.com": "player.vimeo.com/video",
  "dailymotion.com": "www.dailymotion.com/embed/video",
  "dai.ly": "www.dailymotion.com/embed/video",
};
/**
 * Get a video url, detect if it's a youtube or vimeo video and return the url accordingly
 * @param url string
 * @returns embed url or original url
 */
export const getEmbedVideoUrl = (url) => {
  const videoUrl = new URL(url);
  const hostname = videoUrl.hostname.replace("www.", "");
  let videoId = videoUrl.pathname.split("/").pop();
  if (hostname.includes("youtube.com") && videoId.includes("watch")) {
    videoId = videoUrl.searchParams.get("v");
  }
  const embedHost = applicableVideoHost[hostname];
  if (embedHost) {
    return `https://${embedHost}/${videoId}`;
  }
  return url;
};

export const isEmbedVideoUrl = (url) => {
  const matchedHost = Object.keys(applicableVideoHost).find((host) => {
    return url.includes(host);
  });
  return !!matchedHost;
};
